import { styled, Tabs, Theme } from "@mui/material";

interface StyledTabsProps {
  theme: Theme;
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  indicatorColor?: "secondary" | "primary";
  textColor?: "primary" | "secondary" | "inherit";
  variant?: "fullWidth" | "standard" | "scrollable";
  centered?: boolean;
}

export const CustomColoredTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor:
      theme.palette.mode === "dark"
        ? `${theme.palette.getContrastText(theme.palette.background.paper)} !important`
        : theme.palette.primary.main
  }
}));
