import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { Clear, FilterAlt, ViewWeek } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Weekdays } from "../../Pickers/WeekdayPicker/weekday-picker.component";
import { weekdaysToToggle } from "../functions/weekdayToToggle.function";

export function WeekdaysSelector({
  weekdays,
  setWeekdaysToggle,
  onEnableFetch
}: {
  weekdays: Weekdays;
  setWeekdaysToggle: (w: Weekdays) => void;
  onEnableFetch: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [newToggles, setNewToggles] = useState<string[]>([]);

  const boxBorder = `1px solid ${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.12)"}`;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { saturday, sunday, ...onlyWeekdays } = weekdays;

  // shared function to update toggle values and enable to trigger fetch
  const updateToggles = (toggles: string[]) => {
    setNewToggles(toggles);
    setWeekdaysToggle(weekdaysToToggle(toggles));
    onEnableFetch();
  };

  function handleWeekdayToggle(event: any, newToggles: string[]) {
    updateToggles(newToggles);
  }

  function clearWeekdayToggle() {
    updateToggles([]);
    setIsToggleOpen(false);
  }

  function selectAllWeekdayToggle() {
    updateToggles(["monday", "tuesday", "wednesday", "thursday", "friday"]);
    setIsToggleOpen(false);
  }

  useEffect(() => {
    // initially set weekdays toggle
    const initialVal = Object.entries(onlyWeekdays).map(([k, v]) => {
      if (v) return k;
      else return "";
    });
    setNewToggles(initialVal);
  }, []);

  return (
    <Box sx={{ display: "flex", height: 50, border: boxBorder }}>
      {/* open the weekday filter items */}
      <Tooltip title={<Typography>{t("Weekday Filter")}</Typography>} aria-label={"Weekday Filter"}>
        <Button
          variant={"text"}
          color={"inherit"}
          sx={{ minWidth: 54 }}
          onClick={() => setIsToggleOpen(!isToggleOpen)}
          data-testid={`capacity-report-weekday-toggle-open-btn`}
        >
          <ViewWeek />
        </Button>
      </Tooltip>

      <Collapse in={isToggleOpen} orientation={"horizontal"}>
        {/* weekdays toggle button group */}
        <ToggleButtonGroup value={newToggles} onChange={handleWeekdayToggle}>
          {Object.entries(onlyWeekdays).map(([k]) => (
            <Tooltip title={<Typography>{t(k)}</Typography>} key={k} aria-label={k}>
              <ToggleButton
                key={k}
                disableRipple
                sx={{ cursor: "pointer" }}
                value={k}
                selected={newToggles.includes(k)}
                data-testid={`capacity-report-weekday-${k}-toggle-btn`}
              >
                {t(k.slice(0, 2))}
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>

        {/* select all toggle button */}
        <Tooltip
          title={<Typography>{t("Select all weekdays")}</Typography>}
          aria-label={"Select all weekdays"}
        >
          <Button
            sx={{ height: "inherit", minWidth: 54, borderRight: boxBorder }}
            variant={"text"}
            color={"inherit"}
            onClick={selectAllWeekdayToggle}
            data-testid="select-all-weekdays-toggle-btn"
          >
            <FilterAlt />
          </Button>
        </Tooltip>

        {/* clear toggle button */}
        {newToggles.length > 0 && (
          <Tooltip
            title={<Typography>{t("Clear weekday selection")}</Typography>}
            aria-label={"Clear weekday selection"}
          >
            <Button
              sx={{ height: "inherit", minWidth: 54 }}
              variant={"text"}
              color={"inherit"}
              onClick={clearWeekdayToggle}
              data-testid="clear-weekdays-toggle-btn"
            >
              <Clear />
            </Button>
          </Tooltip>
        )}
      </Collapse>
    </Box>
  );
}
