import { forwardRef } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { FilterList, Group, PersonAdd, Update } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { PricingModel } from "../../../features/Login/typings/login.types";
import { NotSerializedBookingMode } from "../../../features/Booking-Form/typings/booking-inputs";
import { ColleagueBookingFilters } from "./ColleagueBookingFilter.component";
import { GroupFilterSelected } from "./types/GroupFilters.type";
import { FloorAvailability } from "../../../features/FloorManager/typings/floor-inventory.entity";
import { ConnectedBookingData } from "../../../features/Booking-Form/typings/connected-bookingData";
import { genConnectedBookingData } from "./functions/BookingFilterButtonGroup.functions";

type P = {
  floorButtonLabel: string;
  bookingMode: NotSerializedBookingMode;
  floorAvailability: FloorAvailability;
  filterItems: GroupFilterSelected[];
  isColleagueFilterOpen: boolean;
  selectedColleagueFilter: ConnectedBookingData[];
  isFilterEntryEmpty: boolean;
  isFilterAble: boolean;
  setIsFloorFilterOpen: () => void;
  setIsGroupFilterOpen: () => void;
  setIsColleagueFilterOpen: () => void;
  setIsDateOpen: () => void;
  setIsTeamBookingOpen: (o: boolean) => void;
  setSelectedColleagueFilter: (filter: ConnectedBookingData[]) => void;
};

export const BookingFilterButtons: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const {
    floorButtonLabel,
    bookingMode,
    floorAvailability,
    isColleagueFilterOpen,
    selectedColleagueFilter,
    isFilterEntryEmpty,
    isFilterAble,
    setIsFloorFilterOpen,
    setIsGroupFilterOpen,
    setIsColleagueFilterOpen,
    setIsDateOpen,
    setIsTeamBookingOpen,
    setSelectedColleagueFilter
  } = props;

  const { t } = useTranslation();
  const { userInformation } = useSelector((state: RootState) => state.login);

  const uniqConnectedBookingData = genConnectedBookingData(
    floorAvailability?.connectedBookingData ?? []
  );

  return (
    <div ref={ref}>
      <Box sx={{ flexGrow: 1, mt: "2px", mb: 1 }} data-testid="booking-filter-button-group-box">
        <Grid container spacing={2} data-testid="booking-filter-button-group-grid-parent">
          {/* First grid item with buttons */}
          <Grid item sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {/* floor button */}
            <Button
              data-testid="booking-filter-floor-btn"
              onClick={setIsFloorFilterOpen}
              disabled={!isFilterAble}
            >
              {floorButtonLabel}
            </Button>

            {/* group-filter button */}
            <Tooltip
              title={
                <Typography>
                  {isFilterEntryEmpty ? t("There are no available filter items") : t("Filter")}
                </Typography>
              }
              aria-label={
                isFilterEntryEmpty ? t("There are no available filter items") : t("Filter")
              }
            >
              <span>
                <Button
                  data-testid="booking-filter-group-filter-btn"
                  onClick={setIsGroupFilterOpen}
                  disabled={isFilterEntryEmpty}
                >
                  <FilterList sx={{ mr: 1 }} />
                  {t("Filter")}
                </Button>
              </span>
            </Tooltip>

            {/* additional button - change time */}
            {userInformation.pricingModels.includes(PricingModel.ENTERPRISE) && (
              <Button
                sx={{ padding: "6px 16px", height: "36.5px" }}
                data-testid="change-time"
                onClick={setIsDateOpen}
                aria-label="change time"
                color={"primary"}
                size="large"
                disabled={!isFilterAble}
              >
                <Update />
              </Button>
            )}
            {/* additional button - team booking mode */}
            {bookingMode === "team" && (
              <Tooltip
                title={<Typography>{t("Edit your team selection")}</Typography>}
                aria-label="Edit your team selection"
              >
                <span>
                  <Button
                    sx={{ padding: "6px 16px", height: "36.5px" }}
                    data-testid="change-team-members"
                    onClick={() => setIsTeamBookingOpen(true)}
                    aria-label="change members"
                    color={"primary"}
                    size="large"
                    disabled={!isFilterAble}
                  >
                    <PersonAdd />
                  </Button>
                </span>
              </Tooltip>
            )}

            {/* find colleagues button */}
            <Tooltip
              title={<Typography>{t("Find your colleagues")}</Typography>}
              aria-label="Find your colleagues"
            >
              <span>
                <Button
                  sx={{ padding: "6px 16px", height: "36.5px" }}
                  data-testid="find-colleagues-btn"
                  onClick={setIsColleagueFilterOpen}
                  color={isColleagueFilterOpen ? "error" : "primary"}
                  size="large"
                  disabled={!isFilterAble}
                >
                  <Group />
                  <Typography sx={{ pl: 1 }}>{uniqConnectedBookingData.length}</Typography>
                </Button>
              </span>
            </Tooltip>
          </Grid>

          {/* Second grid item with Autocomplete */}
          <Grid item xs>
            {/* find colleagues textField filter */}
            {floorAvailability && isColleagueFilterOpen && (
              <ColleagueBookingFilters
                onClose={setIsColleagueFilterOpen}
                connectedBookingData={uniqConnectedBookingData}
                selectedColleagueFilter={selectedColleagueFilter}
                setSelectedColleagueFilter={setSelectedColleagueFilter}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
});
