import { forwardRef } from "react";
import { Box, Button, CardContent, Grid, Typography } from "@mui/material";
import { Group } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LocationAvailability } from "../../../features/Booking-Form/typings/booking-location";
import { ConnectedBookingData } from "../../../features/Booking-Form/typings/connected-bookingData";

type P = {
  location: LocationAvailability;
  spotsAvailable: boolean | undefined;
  uniqConnectedBookingData: ConnectedBookingData[];
  locationIcons: JSX.Element;
  isMobile: boolean;
  setIsColleagueDataOpen: (o: boolean) => void;
};

export const LocationCardContent: React.FC<P> = forwardRef<HTMLDivElement, P>(
  (
    {
      location,
      spotsAvailable,
      uniqConnectedBookingData,
      locationIcons,
      isMobile,
      setIsColleagueDataOpen
    },
    ref
  ) => {
    const { t } = useTranslation();

    const { numberOfBookableObjects, name, address1, address2, postalCode, city } = location;

    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", maxWidth: 345, width: "100%" }}
        ref={ref}
        data-testid="location-card-content-box"
      >
        <CardContent sx={{ flex: "0 0 auto", p: "16px 12px" }}>
          <Grid container>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ overflowWrap: "anywhere" }}>
                {name === "Mobile Working" ? t("Mobile Working") : name}
              </Typography>
            </Grid>
          </Grid>

          {name !== "Mobile Working" && (
            <div style={{ marginTop: 5 }}>
              {city}, {postalCode}
              <Typography variant="subtitle1" color="textSecondary">
                {address1} {address2}
              </Typography>
            </div>
          )}
        </CardContent>

        <Grid
          container
          data-testid="location-card-bottm-contents"
          sx={{
            alignItems: "center",
            paddingLeft: 2,
            paddingBottom: 1,
            paddingRight: isMobile ? 0.5 : 0,
            justifyContent: "space-between"
          }}
        >
          <Grid item sx={{ alignSelf: "center" }}>
            {spotsAvailable ? (
              <Typography
                sx={{ color: "#43a047", fontSize: "1.1rem" }}
                variant="h6"
                color="textSecondary"
              >
                {t("_available")} {numberOfBookableObjects}
              </Typography>
            ) : (
              locationIcons
            )}
          </Grid>
          <Grid item sx={{ mr: isMobile ? 0 : 0.5 }}>
            {name !== "Mobile Working" && uniqConnectedBookingData && (
              <Button
                variant={"outlined"}
                sx={{ p: 0.5 }}
                disabled={!uniqConnectedBookingData.length}
                data-testid="show-connected-bookingData-btn"
                onClick={e => {
                  e.stopPropagation();
                  setIsColleagueDataOpen(true);
                }}
              >
                <Group />
                <Typography sx={{ pl: 0.5, fontSize: "0.9rem" }}>
                  {uniqConnectedBookingData.length}
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
);
