import { useEffect, useState } from "react";
import { ColorOverlayFilter } from "@pixi/filter-color-overlay";
import { Graphics, Sprite } from "@pixi/react";
import { PlaceCircle, WorkplaceRect } from "./SpriteIndex";
import BoundingBox from "../../../Domain/Types/BoundingBox.type";
import { PlaceVariant } from "../../../Domain/Types/FloorPlan/PlaceVariant.type";
import { ToolSelection } from "../../Views/CreateFloorPlanView/CreateFloorPlanView";
import { variantCircle } from "./PlaceSprite.functions";
import { drawPlaceColor } from "../functions/drawPlaceColor.function";

interface Props {
  boundingBox: BoundingBox;
  filterGroup: ColorOverlayFilter[];
  variantColor: number;
  variant: PlaceVariant;
  placeScale: number;
  isSelectable: boolean;
  isHighlighted?: boolean;
  colorOverlay: number | undefined;
  tool?: ToolSelection;
  isColleaguesPlace?: boolean;
  isGrpPreferred: boolean;
}

/**
 * draw the workplace sprite by pixi-sprite or pixi-graphic according to module(booking/creating)
 * @version 1.0.0
 */
export function WorkPlaceSprite({
  boundingBox,
  filterGroup,
  variantColor,
  variant,
  placeScale,
  isHighlighted,
  colorOverlay,
  isSelectable,
  tool,
  isColleaguesPlace,
  isGrpPreferred
}: Props) {
  const { width = 160, height = 80 } = boundingBox;

  const [circleRadius, setCircleRadius] = useState(width * placeScale * 0.2);
  const [lineWidth, setLineWidth] = useState(width * placeScale * 0.04);

  const circleSize = (width * placeScale) / 2.1;
  const circlePos = 0 - circleSize / 2;

  const { rectColor, circColor } = drawPlaceColor({
    isHighlighted: isHighlighted ?? false,
    colorOverlay,
    variantColor,
    tool
  });

  useEffect(() => {
    setCircleRadius(width * placeScale * 0.2);
    setLineWidth(width * placeScale * 0.04);
  }, [width, height, placeScale]);

  /** draw sprite by SVG Image when is not graphic repreferred(firefox) and booking mode */
  const isDrawSprSvg = !isGrpPreferred && tool === "SELECT.BOOK";
  /** draw sprite by PIXI.Graphics when graphic repreferred(firefox) and not booking mode */
  const isDrawSprGrp = isGrpPreferred || tool !== "SELECT.BOOK";

  return (
    <>
      {isDrawSprSvg && (
        <WorkplaceSpriteSvgImg
          isSelectable={isSelectable}
          width={width}
          height={height}
          placeScale={placeScale}
          filterGroup={filterGroup}
          isColleaguesPlace={isColleaguesPlace}
          circleSize={circleSize}
          circlePos={circlePos}
          variant={variant}
        />
      )}

      {isDrawSprGrp && (
        <WorkplaceSpriteGraphic
          isSelectable={isSelectable}
          width={width}
          height={height}
          placeScale={placeScale}
          filterGroup={filterGroup}
          isColleaguesPlace={isColleaguesPlace}
          rectColor={rectColor}
          lineWidth={lineWidth}
          circColor={circColor}
          circleRadius={circleRadius}
        />
      )}
    </>
  );
}

/** draw sprite by SVG Image when is not graphic repreferred(firefox) and booking mode */
export function WorkplaceSpriteSvgImg({
  isSelectable,
  width,
  height,
  placeScale,
  filterGroup,
  isColleaguesPlace,
  circleSize,
  circlePos,
  variant
}: {
  isSelectable: boolean;
  width: number;
  height: number;
  placeScale: number;
  filterGroup: ColorOverlayFilter[];
  isColleaguesPlace?: boolean;
  circleSize: number;
  circlePos: number;
  variant: PlaceVariant;
}) {
  return (
    <>
      {/* Sprite Object, rectangle of work places */}
      <Sprite
        data-testid="workplace-spr-rect"
        image={WorkplaceRect}
        cursor={isSelectable ? "pointer" : "auto"}
        position={{ x: 0 - (width * placeScale) / 2, y: -16 * placeScale }}
        width={width * placeScale}
        height={height * placeScale}
        filters={filterGroup}
      />
      {/* Sprite Object, circle of work places */}
      {isColleaguesPlace ? (
        <Sprite
          data-testid="workplace-colleagues-spr-circ"
          image={PlaceCircle.Occupied}
          cursor={isSelectable ? "pointer" : "auto"}
          width={circleSize * 2.2}
          height={circleSize * 2.2}
          position={{ x: circlePos * 2.2, y: circlePos * 2.2 - 16 * placeScale }}
          filters={filterGroup}
        />
      ) : (
        <Sprite
          data-testid="workplace-spr-circ"
          image={variantCircle(variant)}
          cursor={isSelectable ? "pointer" : "auto"}
          width={circleSize}
          height={circleSize}
          position={{ x: circlePos, y: circlePos - 16 * placeScale }}
          filters={filterGroup}
        />
      )}
    </>
  );
}

export function WorkplaceSpriteGraphic({
  isSelectable,
  width,
  height,
  placeScale,
  filterGroup,
  isColleaguesPlace,
  rectColor,
  lineWidth,
  circColor,
  circleRadius
}: {
  isSelectable: boolean;
  width: number;
  height: number;
  placeScale: number;
  filterGroup: ColorOverlayFilter[];
  isColleaguesPlace?: boolean;
  rectColor: number;
  lineWidth: number;
  circColor: number;
  circleRadius: number;
}) {
  return (
    <>
      {/* Graphic objects, rectangle of places */}
      <Graphics
        data-testid="workplace-grp-rect"
        cursor={isSelectable ? "pointer" : "auto"}
        position={{ x: 0 - (width * placeScale) / 2, y: -16 * placeScale }}
        draw={g => {
          g.clear();
          /** g.beginFill(0xd2dae1, 1); */
          g.beginFill(rectColor, 1);
          g.drawRoundedRect(0, 0, width * placeScale, height * placeScale, 13 * (placeScale / 2));
          g.endFill();
        }}
        filters={filterGroup}
      />
      {/* Graphic objects, circle of places */}
      {isColleaguesPlace ? (
        <Graphics
          data-testid="workplace-grp-circ"
          cursor={isSelectable ? "pointer" : "auto"}
          position={{ x: 0, y: -16 * placeScale }}
          filters={filterGroup}
          draw={g => {
            g.clear();
            g.lineStyle(lineWidth, 0xffffff); // prev: 6 * placeScale
            g.beginFill(0xff0000, 1);
            g.drawCircle(0, 0, circleRadius * 2.2); // prev : 30 * placeScale
            g.endFill();
          }}
        />
      ) : (
        <Graphics
          data-testid="workplace-grp-circ"
          cursor={isSelectable ? "pointer" : "auto"}
          position={{ x: 0, y: -16 * placeScale }}
          filters={filterGroup}
          draw={g => {
            g.clear();
            g.lineStyle(lineWidth, 0xffffff); // prev: 6 * placeScale
            /** g.beginFill(variantColor, 1); */
            g.beginFill(circColor, 1);
            g.drawCircle(0, 0, circleRadius); // prev : 30 * placeScale
            g.endFill();
          }}
        />
      )}
    </>
  );
}
