import React, { SetStateAction, useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Skeleton,
  Box,
  FormControlLabel,
  Switch
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  getCurrentFloor,
  setBookingFiltersState
} from "../../features/Booking-Form/functions/form.functions";
import {
  selectSingleWorkplace,
  selectTeamMemberWorkplace,
  setBookingFilters,
  setFloors,
  setInputs
} from "../../features/Booking-Form/slices/booking.slice";
import { SelectFloorPlanView } from "../FacilityManager/Components/Views/SelectFloorPlanView/SelectFloorPlanView";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import {
  checkActivityBased,
  checkCurrentUser,
  fetchFloorPlanState,
  applyBookingFilterOnFloorPlan,
  filterListByCategory,
  handleFilterSelect,
  tempFilterListByCategory,
  updateFilters,
  initCategoryOptions,
  initFilterOptions
} from "./functions/form.functions";
import { useBackgroundImage } from "../FacilityManager/Hooks/useBackgroundImage";
import { FormEditTimeDialog } from "./form-edit-time-dialog.component";
import { BookingFilterButtons } from "./BookingFilter/BookingFilterButtonGroup.component";
import { SlideoutPanelDialog } from "../SlideoutPanel/SlideoutPanelDialog.component";
import { FloorFilters } from "./BookingFilter/FloorFilters.component";
import { generateFloorButtonLabel } from "./BookingFilter/functions/FloorFilter.functions";
import { GroupFilters } from "./BookingFilter/GroupFilters.component";
import { GroupFilterSelected, GroupFilterType } from "./BookingFilter/types/GroupFilters.type";
import FormWorkplaceTeamBookingChips from "./form-workplace-team-booking-chips.component";
import { ConnectedBookingData } from "../../features/Booking-Form/typings/connected-bookingData";
import FormTeamSelection from "./FormTeamSelection/form-team-selection.component";

type P = {
  nextStep: () => void;
  prevStep: () => void;
  workplaceToState: (id: number) => void;
  picker: boolean;
  setValidDates: React.Dispatch<SetStateAction<boolean>>;
  validDate: boolean;
  teamToState?: (index: number, workplace: number) => void;
  preferredLocations: number[];
};

/**
 * @description Component that displays the location floors and blueprints including the desks and zones.
 * @param props.nextStep as a function which goes to the next step in the booking process.
 * @param props.prevStep as a function which goes to the previous step in the booking process.
 * @param props.workplaceToState as a function which sets the selected workplace to main state.
 * @param props.teamToState as a function which sets the selected team including the selected workplaces to the main state usersBookedFor array.
 * @param props.preferredLocations used to filter equipment and equipment category previously. not used for now.
 * @version 0.1.1
 */
export const FormWorkplace: React.FC<P> = props => {
  const { t } = useTranslation();
  const {
    inputs,
    floors: { inventory, currentFloorIndex, isLoading, error }
  } = useSelector((state: RootState) => state.booking);
  const {
    userInformation,
    settings: { preferredPlaceCategories }
  } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();

  const [floorPlan, setFloorPlan] = useState<IFloorPayload>();
  const [currentUser, setCurrentUser] = useState<number>(
    checkCurrentUser(inputs.usersBookedFor) // index of user we are currently selecting a table for, but need to check the value shouldn't be -1
  );

  // filter place/zone by category selection
  const [filteredSelectables, setFilteredSelectables] = useState<number[] | undefined>(undefined);

  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isFloorFilterOpen, setIsFloorFilterOpen] = useState(false);
  const [isGroupFilterOpen, setIsGroupFilterOpen] = useState(false);
  const [detailGroupFilter, setDetailGroupFilter] = useState(false);
  const [isTeamBookingOpen, setIsTeamBookingOpen] = useState(false);
  const [isColleagueFilterOpen, setIsColleagueFilterOpen] = useState(false);
  const [selectedColleagueFilter, setSelectedColleagueFilter] = useState<ConnectedBookingData[]>(
    []
  );
  const [filterItems, setFilterItems] = useState<GroupFilterSelected[]>([]);
  const [filtersChanged, setFiltersChanged] = useState(false);

  const loaded = useRef<boolean>(false);

  // get current floor by currentFloorIndex, initially select the most bookable floor for the first floor
  const currentFloor = getCurrentFloor(dispatch, inventory, currentFloorIndex);

  // download and set the background image here
  const { background, unsetBackgroundImage } = useBackgroundImage({
    backgroundImageUrl: floorPlan?.outlineUrl,
    viewport: floorPlan?.viewport
  });

  // initiate place/zone category options
  const usedCategory = initCategoryOptions(inputs.mode, floorPlan, currentFloor);
  // initiate equipment categories and booking properties options
  const usedOptions = initFilterOptions(inputs.mode, floorPlan);

  const isFilterEntryEmpty =
    usedCategory.options.length === 0 &&
    usedOptions?.usedBookingProperties.length === 0 &&
    usedOptions.usedEquipmentCategories.length === 0;

  useEffect(() => {
    setCurrentUser(checkCurrentUser(inputs.usersBookedFor));
  }, [inputs.usersBookedFor]);

  useEffect(() => {
    setFiltersChanged(true);
  }, [inputs.bookingFilters, inputs.floorInventoryId]);

  useEffect(() => {
    checkActivityBased(inputs.activityBasedBooking, props.nextStep);
  }, [inputs.activityBasedBooking, props.nextStep]);

  useEffect(() => {
    updateFilters(inputs, currentUser, loaded, dispatch, setFiltersChanged, filtersChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputs.bookingEnd,
    inputs.bookingFrom,
    inputs.bookingStart,
    inputs.bookingTo,
    inputs.bookingType,
    inputs.selectedLocation,
    inputs.usersBookedFor,
    inputs.bookingFilters,
    inputs.floorInventoryId,
    inputs.weekdays,
    inputs.mode,
    inputs.frequence,
    inputs.interval,
    inputs.specificDays,
    inputs.bookingMonthDay,
    inputs.bookingYearDay,
    filtersChanged,
    currentUser
  ]);

  useEffect(() => {
    fetchFloorPlanState(inputs, setFloorPlan, currentFloor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputs.automatedSeating,
    inputs.bookingEnd,
    inputs.bookingFrom,
    inputs.bookingStart,
    inputs.bookingTo,
    inputs.activityBasedBooking,
    inputs.isMobileWorking,
    currentFloor?.id
  ]);

  useEffect(() => {
    // when team mode and current user is matching
    setBookingFiltersState(inputs.mode, inputs.usersBookedFor, currentUser, dispatch);
  }, [inputs.mode, inputs.usersBookedFor, currentUser, dispatch]);

  // initially, use preferred place category
  useEffect(() => {
    if (floorPlan) {
      // we only use preferred place category but later when we adapt zone category then need to refactor
      if (usedCategory.type === "place" && preferredPlaceCategories.length > 0) {
        let preferredFilterItems = preferredPlaceCategories.map(cat => ({
          type: GroupFilterType.PLACECATEGORY,
          id: Number(cat),
          name:
            floorPlan.placeCategories.find(cate => cate.id === Number(cat))?.name ?? "preferred",
          checked: true
        }));

        // but deduct not used one from the floor plan
        preferredFilterItems = preferredFilterItems.filter(item =>
          usedCategory.options.some(opt => opt.id === item.id)
        );

        dispatch(
          setBookingFilters({ placeCategoryIds: preferredFilterItems.map(pref => pref.id) })
        );
        setFilterItems(preferredFilterItems);
      }
    }
  }, [floorPlan, currentFloorIndex]);

  // apply category filter on the floor plan
  useEffect(() => {
    if (floorPlan) {
      applyBookingFilterOnFloorPlan(inputs, floorPlan, filterItems, setFilteredSelectables);
    }
  }, [inputs.bookingFilters, floorPlan, currentFloorIndex]);

  /**
   * @deprecated it used to be that if current floor has no empty workplaces select the next floor.
   * selectWorkplaceFloor(inventory, dispatch, currentFloorIndex);
   */

  if (error)
    return <Typography variant={"h5"}>{t("There was an extreme error getting floors")}</Typography>;

  return (
    <>
      <Grid data-testid="form-booking-parent" container>
        <Grid
          data-testid="form-booking-select-parent"
          item
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            display: "inline-block"
          }}
        >
          {/* new booking filter buttons */}
          <BookingFilterButtons
            bookingMode={inputs.mode}
            floorAvailability={inventory[currentFloorIndex ?? 0]}
            floorButtonLabel={generateFloorButtonLabel(inventory, currentFloorIndex)}
            isColleagueFilterOpen={isColleagueFilterOpen}
            setIsFloorFilterOpen={() => setIsFloorFilterOpen(true)}
            setIsGroupFilterOpen={() => setIsGroupFilterOpen(true)}
            setIsColleagueFilterOpen={() => setIsColleagueFilterOpen(!isColleagueFilterOpen)}
            setIsDateOpen={() => setIsDateOpen(true)}
            setIsTeamBookingOpen={setIsTeamBookingOpen}
            filterItems={filterItems}
            selectedColleagueFilter={selectedColleagueFilter}
            setSelectedColleagueFilter={setSelectedColleagueFilter}
            isFilterEntryEmpty={isFilterEntryEmpty}
            isFilterAble={!!floorPlan && !isLoading && !!background}
          />

          {/* floor filter slideout panel */}
          <SlideoutPanelDialog
            isOpen={isFloorFilterOpen}
            dialogTitle={t("Floor")}
            width="20vw"
            showCloseButton
            onClose={() => setIsFloorFilterOpen(false)}
          >
            <FloorFilters
              floorInventory={inventory}
              currentFloor={currentFloor}
              onSelectFloor={(id, index) => {
                dispatch(setInputs({ floorInventoryId: id }));
                dispatch(setFloors({ currentFloorIndex: index }));
                unsetBackgroundImage();
                setIsFloorFilterOpen(false);
              }}
            />
          </SlideoutPanelDialog>

          {/* group filter slideout panel */}
          <SlideoutPanelDialog
            isOpen={isGroupFilterOpen}
            dialogTitle={t("Filter")}
            width={detailGroupFilter ? "35vw" : "25vw"}
            showCloseButton
            showDetailToggle
            detailGroupFilterToggle={
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Switch
                    data-testid="show-detail-toggle"
                    value={detailGroupFilter}
                    onChange={() => setDetailGroupFilter(!detailGroupFilter)}
                  />
                }
                label={detailGroupFilter ? t("Show details") : t("Hide details")}
                slotProps={{ typography: { sx: { width: "fit-content" } } }}
              />
            }
            onClose={() => {
              setIsGroupFilterOpen(false);
              setDetailGroupFilter(false);
            }}
            primaryButtonTitle={t("Apply")}
            secondaryButtonTitle={t("Reset")}
            primaryButtonAction={() =>
              // handle to apply filter items
              handleFilterSelect(filterItems, inputs, currentUser, dispatch)
            }
            secondaryButtonAction={() => {
              setFilterItems([]);
              setFilteredSelectables(undefined);
            }}
          >
            <GroupFilters
              floorPlan={floorPlan}
              showDetailGroupFilter={detailGroupFilter}
              filterItems={filterItems}
              setFilterItems={setFilterItems}
              usedCategory={usedCategory}
              usedOptions={usedOptions}
            />
          </SlideoutPanelDialog>
        </Grid>

        {/* team booking mode - chips of booking member */}
        <FormWorkplaceTeamBookingChips
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          inputs={inputs}
        />

        {/* floor plan part */}
        {(isLoading || !background) && (
          <Skeleton variant="rectangular" sx={{ width: "100%", height: "calc(100vh - 250px)" }} />
        )}
        {!isLoading && inputs.usersBookedFor && currentFloor && floorPlan && background && (
          <Box
            data-testid="select-floorplanview-parent"
            id="select-floor-parent"
            sx={{ width: "100%", height: "calc(85vh - 250px)" }}
          >
            <SelectFloorPlanView
              background={background}
              name={"booking"}
              floorPlan={floorPlan}
              seatStatus={{
                availableList: filterListByCategory(
                  currentFloor.availableInventoryIds,
                  filteredSelectables
                ),
                tempList: tempFilterListByCategory(
                  currentFloor.availableInventoryIds,
                  filteredSelectables
                ),
                occupiedList: currentFloor.occupiedInventoryIds,
                disableList: currentFloor.disabledInventoryIds,
                restrictedList: currentFloor.forbiddenInventoryIds
              }}
              userOccupiedPlaces={currentFloor.userOccupiedInventoryIds ?? []}
              bookingInputs={{
                mode: inputs.mode,
                bookingFrom: inputs.bookingFrom,
                bookingTo: inputs.bookingTo,
                bookingStart: inputs.bookingStart,
                bookingEnd: inputs.bookingEnd,
                bookingType: inputs.bookingType,
                usersBookedFor: inputs.usersBookedFor,
                timezone: inputs.timezone,
                weekdays: inputs.weekdays,
                zoneAccess: inputs.zoneAccess
              }}
              userIndex={currentUser}
              setUserIndex={(i: number) => setCurrentUser(i)}
              warningData={{
                requiredUserWarningData: currentFloor.requiredUserWarningData,
                optionalUserWarningData: currentFloor.optionalUserWarningData
              }}
              onSingleSelect={(placeInventoryId: number) => {
                dispatch(
                  selectSingleWorkplace({
                    firstName: userInformation.firstName,
                    surname: userInformation.surname,
                    userId: userInformation.sub,
                    bookingInventoryId: placeInventoryId,
                    email: userInformation.email,
                    isExternal: false
                  })
                );
                props.nextStep();
              }}
              onTeamSelect={(index: number, placeInventoryId: number) => {
                dispatch(selectTeamMemberWorkplace({ index, placeInventoryId: placeInventoryId }));
              }}
              onNextStep={props.nextStep}
              setIsDateOpen={setIsDateOpen}
              selectedColleagueFilter={isColleagueFilterOpen ? selectedColleagueFilter : []}
            />
          </Box>
        )}
      </Grid>

      {/* change time dialog */}
      <Dialog
        data-testid="dialog-date-open"
        onClose={() => setIsDateOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isDateOpen}
      >
        <DialogContent sx={{ p: 2 }}>
          <FormEditTimeDialog inputs={inputs} setIsDateOpen={setIsDateOpen} />
        </DialogContent>
      </Dialog>

      {/* change team booking member dialog */}
      <Dialog
        data-testid="dialog-team-open"
        onClose={() => setIsTeamBookingOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isTeamBookingOpen}
      >
        <DialogContent data-testid="dialog-team-content">
          <FormTeamSelection title={t("Edit your team selection")} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormWorkplace;
