import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import {
  RemoteDepartmentReport,
  useRemoteFetchDepartmentReport
} from "../../../hooks/Remote/useRemoteFetchDepartmentReport";
import {
  RemoteUserReport,
  RemoteUserReportEntry,
  useRemoteFetchEmployeeReport
} from "../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { calculateAllLocationsFromAllUsers } from "../SupervisorReport/functions";
import { DateRange, TabPanel } from "../SupervisorReport/supervisor-report.component";
import { ReportTabs, ReportUserListTab } from "../components";
import { EmployeeReportType } from "../typings/EmployeeReportType";
import { GenericLocationUsage } from "../typings/GenericLocationUsage";
import { ReportDepartmentList } from "./ReportDepartmentList/ReportDepartmentList";
import { calculateAllLocationsFromAllDepartments } from "./functions";
import PlaceBookingApproval from "../../../features/Approval/place-booking-approval.component";
import { CustomColoredTab } from "../../CustomColoredTabs/CustomColoredTab.component";

export function createEmployeeDateMap(
  users: RemoteUserReportEntry[],
  start: DateTime,
  end: DateTime
): Map<string, DateRange> {
  const userMap = new Map<string, DateRange>();
  let newStart: DateTime = start;
  let newEnd: DateTime = end;

  if (!start.isValid) {
    newStart = DateTime.now();
  }
  if (!end.isValid) {
    newEnd = DateTime.now();
  }

  for (const supervisorEmployee of users) {
    userMap.set(supervisorEmployee.userId, {
      start: newStart.toISO() as string,
      end: newEnd.toISO() as string
    });
  }
  return userMap;
}

export function createDepartmentDateMap(
  departments: RemoteDepartmentReport,
  start: DateTime,
  end: DateTime
) {
  const departmentMap = new Map<string, DateRange>();

  let newStart: DateTime = start;
  let newEnd: DateTime = end;

  if (!start.isValid) {
    newStart = DateTime.now();
  }
  if (!end.isValid) {
    newEnd = DateTime.now();
  }

  for (const department of departments) {
    departmentMap.set(department.departmentId, {
      start: newStart.toISO() as string,
      end: newEnd.toISO() as string
    });
  }
  return departmentMap;
}

export function HumanResourcesReport() {
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState(0);
  const [allLocations, setAllLocations] = useState<any[]>();
  const [allDepartmentLocations, setAllDepartmentLocations] = useState<GenericLocationUsage[]>();
  const [reportType, setReportType] = useState<EmployeeReportType | undefined>();
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState({
    start: DateTime.now().minus({ day: 30 }),
    end: DateTime.now()
  });

  const { data: employeeReport, isLoading: employeeReportIsLoading } = useRemoteFetchEmployeeReport(
    {
      reportType: EmployeeReportType.HR,
      start: dateRange.start.toFormat("yyyy-MM-dd"),
      end: dateRange.end.toFormat("yyyy-MM-dd")
    }
  );

  const { data: departmentReport, isLoading: departmentReportIsLoading } =
    useRemoteFetchDepartmentReport({
      start: dateRange.start.toFormat("yyyy-MM-dd"),
      end: dateRange.end.toFormat("yyyy-MM-dd")
    });

  const [employeeDates, setEmployeeDates] = useState<Map<string, DateRange> | undefined>();
  const [departmentDates, setDepartmentDates] = useState<Map<string, DateRange> | undefined>();

  function updateEmployeeDates(nextEmployeeReport: RemoteUserReport) {
    setEmployeeDates(createEmployeeDateMap(nextEmployeeReport, dateRange.start, dateRange.end));
  }

  function updateDepartmentDates(nextDepartmentReport: RemoteDepartmentReport) {
    setDepartmentDates(
      createDepartmentDateMap(nextDepartmentReport, dateRange.start, dateRange.end)
    );
  }

  // init all temporary date ranges
  useEffect(() => {
    if (!employeeReport) return;
    setReportType(EmployeeReportType.HR);
    const allLocationsFromAllUsers = calculateAllLocationsFromAllUsers(employeeReport);

    updateEmployeeDates(employeeReport);
    setAllLocations(allLocationsFromAllUsers);
  }, [employeeReport]);

  useEffect(() => {
    if (!departmentReport) return;
    const allLocationsFromAllDepartments =
      calculateAllLocationsFromAllDepartments(departmentReport);

    updateDepartmentDates(departmentReport);
    setAllDepartmentLocations(allLocationsFromAllDepartments);
  }, [departmentReport]);

  return (
    <>
      <ReportTabs data-testid="hr-tabs" selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
        <CustomColoredTab label={t("Departments")} index={0} theme={theme} />
        <CustomColoredTab label={t("Employees")} index={1} theme={theme} />
        <CustomColoredTab label={t("_hrApprovalTab")} index={2} theme={theme} />
      </ReportTabs>

      {/** Departments */}
      <TabPanel value={selectedTab} index={0}>
        <ReportDepartmentList
          allLocations={allDepartmentLocations}
          departmentDates={departmentDates}
          dateRange={dateRange}
          setDateRange={setDateRange}
          departmentReport={departmentReport}
          departmentReportIsLoading={departmentReportIsLoading}
        />
      </TabPanel>

      {/** Employees */}
      <TabPanel value={selectedTab} index={1}>
        <ReportUserListTab
          {...{
            selectedTab,
            allLocations,
            employeeDates,
            dateRange,
            setDateRange,
            employeeReport,
            employeeReportIsLoading,
            reportType
          }}
        />
      </TabPanel>

      {/* booking approval */}
      <TabPanel value={selectedTab} index={2}>
        <PlaceBookingApproval isSupervisorPerspective={false} />
      </TabPanel>
    </>
  );
}
