import React from "react";
import { MbscCalendarEventData } from "@mobiscroll/react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Cancel, DoNotDisturb, HourglassEmpty } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { TeamMember } from "../../features/Booking-Form/typings/team-member";
import { ScheduleEventCheckbox } from "./schedule-event-checkbox.component";
import { ScheduleEventContentIcon } from "./schedule-event-content-icon.component";
import { hexToRGB } from "../FacilityManager/Functions/hexColorToRGB";
import { calBrightness } from "../FacilityManager/Functions/calculateBrightness";
import { BookingRequestsStatus } from "../BookingRequest/typings/booking-requests.types";

type P = {
  event: MbscCalendarEventData;
  monthView?: boolean;
  schedule?: BookingScheduleInterface;
  selectedColleagues?: TeamMember[];
  users?: TeamMember[];
  setSelectedAppointment?: (selectedAppointment: number) => void;
  selectedAppointments?: number[];
};

export const ScheduleEventContent: React.FC<P> = ({
  event,
  monthView,
  schedule,
  selectedColleagues,
  users,
  selectedAppointments,
  setSelectedAppointment
}) => {
  const { t } = useTranslation();
  const { userInformation } = useSelector((state: RootState) => state.login);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!schedule) return <Typography>{event.title}</Typography>;
  const displayCheckbox =
    DateTime.fromISO(schedule.startDate).valueOf() > DateTime.now().valueOf() &&
    (selectedColleagues
      ? selectedColleagues
          .map((colleague: { userId: string }) => colleague.userId)
          .includes(userInformation.sub) && schedule.userBookedFor === userInformation.sub
      : true);

  const bookedForUser = users?.find(user => user.userId === schedule.userBookedFor);
  const multipleUsersSelected = users && users.length >= 2;
  const bookedForUserName = `${bookedForUser?.firstName} ${bookedForUser?.surname}`.trim();
  const showUserName = multipleUsersSelected && bookedForUser;
  const locationName =
    schedule.locationName !== "Mobile Working"
      ? `${schedule.locationName ?? schedule.location}`
      : t("Mobile Working");
  const floorName = schedule.locationName !== "Mobile Working" && `${schedule.floorName}`;

  return (
    <>
      <Grid container data-testid="calendar-item">
        <Grid
          item
          xs={displayCheckbox ? 10 : 12}
          style={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            position: "relative"
          }}
        >
          {!isMobile && (
            <InitStatusIcon
              status={schedule.status}
              color={calBrightness(hexToRGB(event.original?.color))}
            />
          )}

          <ScheduleEventContentIcon
            schedule={schedule}
            userId={userInformation.sub}
            textColor={calBrightness(hexToRGB(event.original?.color))}
          />

          {showUserName ? (
            <Typography
              variant={"inherit"}
              sx={{ color: calBrightness(hexToRGB(event.original?.color)) }}
            >
              {bookedForUserName}
            </Typography>
          ) : (
            <>
              <Typography
                variant={"inherit"}
                sx={{
                  paddingLeft: 0.2,
                  color: calBrightness(hexToRGB(event.original?.color)), // theme.palette.primary.contrastText, // theme.palette.getContrastText(theme.palette.secondary.main)
                  textDecoration:
                    schedule.status === BookingRequestsStatus.REJECTED ? "line-through" : ""
                }}
              >
                {locationName} {" - "} {floorName}
              </Typography>
            </>
          )}
        </Grid>

        {!monthView && (
          <Grid container data-testid="calendar-item-week-view" sx={{ flexDirection: "column" }}>
            {showUserName && (
              <Grid item>
                <Typography
                  variant={"inherit"}
                  sx={{ color: calBrightness(hexToRGB(event.original?.color)) }}
                >
                  {event.title}
                </Typography>
              </Grid>
            )}

            <Grid item>
              <Typography
                variant={"inherit"}
                sx={{ color: calBrightness(hexToRGB(event.original?.color)) }}
              >
                {event.start} - {event.end}
              </Typography>
            </Grid>
          </Grid>
        )}

        {displayCheckbox && (
          <ScheduleEventCheckbox
            schedule={schedule}
            selectedAppointments={selectedAppointments}
            setSelectedAppointment={setSelectedAppointment}
          />
        )}
      </Grid>
    </>
  );
};

export function InitStatusIcon({
  status,
  color
}: {
  status: BookingRequestsStatus | undefined;
  color?: "black" | "white";
}) {
  switch (status) {
    case BookingRequestsStatus.APPROVED:
      return "";
    case BookingRequestsStatus.CANCELLED:
      return <Cancel fontSize="small" sx={{ color: color }} />;
    case BookingRequestsStatus.REJECTED:
      return <DoNotDisturb fontSize="small" sx={{ color: color }} />;
    case BookingRequestsStatus.REQUESTED:
      return <HourglassEmpty fontSize="small" sx={{ color: color }} />;
    default:
      return "";
  }
}
