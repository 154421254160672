import React, { forwardRef, useEffect, useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConnectedBookingData } from "../../../features/Booking-Form/typings/connected-bookingData";

type P = {
  connectedBookingData: ConnectedBookingData[] | undefined;
  selectedColleagueFilter: ConnectedBookingData[];
  setSelectedColleagueFilter: (filter: ConnectedBookingData[]) => void;
  onClose?: () => void;
};

export const ColleagueBookingFilters: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { connectedBookingData, selectedColleagueFilter, setSelectedColleagueFilter } = props;

  const { t } = useTranslation();

  const [options, setOptions] = useState<ConnectedBookingData[]>([]);

  const handleColleaguesChange = (event: React.SyntheticEvent, value: ConnectedBookingData[]) => {
    setSelectedColleagueFilter(value);
  };

  useEffect(() => {
    // initially select all the booking data items
    setSelectedColleagueFilter(connectedBookingData ?? []);
  }, []);

  useEffect(() => {
    if (!connectedBookingData) return setOptions([]);

    setOptions(connectedBookingData);
  }, [connectedBookingData]);

  return (
    <>
      <Autocomplete
        ref={ref}
        data-testid="find-colleagues-autocomplete"
        multiple
        disableCloseOnSelect
        id="tags-outlined8"
        options={options}
        disableClearable
        value={selectedColleagueFilter}
        onChange={handleColleaguesChange}
        renderOption={(props, option) => <BookingMemeberOptionList props={props} option={option} />}
        noOptionsText={t("No Options")}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            data-testid={"find-colleagues-field"}
            placeholder={t("Find your colleagues")}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.userId}
              data-testid={`${option.userId}-select-chip`}
              label={option.firstName + " " + option.lastName}
            />
          ));
        }}
        getOptionLabel={option => option.firstName + " " + option.lastName}
        isOptionEqualToValue={(option, value) => option.userId === value.userId}
      />
    </>
  );
});

export function BookingMemeberOptionList({
  props,
  option
}: {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: ConnectedBookingData;
}) {
  return (
    <li {...props}>
      <div data-testid="team-selection-option">{option.firstName + " " + option.lastName}</div>
    </li>
  );
}
